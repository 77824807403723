<template>
	<Popup name="abeilles"
	text="<h3>Les abeilles</h3><br/><p>La section Apiculture a été créée en 2008 au sein de la raffinerie de Donges. Depuis, ce sont des salariés bénévoles, membres de la section, qui prennent soin au quotidien des abeilles et des 12 ruches présentes sur le site. Le miel est récolté et vendu aux salariés par le comité social et économique (CSE).</p><br/><p>En 2019, un record a été atteint avec une récolte de miel de 98 kg.</p>"
	video="static/video/apiculture.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/abeilles_mmv6v6_c_scale_w_350.jpg 350w,
		img/popup/abeilles_mmv6v6_c_scale_w_726.jpg 726w,
		img/popup/abeilles_mmv6v6_c_scale_w_970.jpg 970w"
		data-src="img/popup/abeilles_mmv6v6_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
